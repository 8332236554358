<template>
    <v-card>
        <v-card-title class="text-h5">
            {{ $t('creditPayment') }}
        </v-card-title>

        <v-card-text>
            <p>{{ $t('creditPaymentInfo') }}</p>
            <div>
                <v-flex xs12 md12>
                    <v-text-field
                        v-model="amountToCredit"
                        label="Amount to credit"
                        type="number"
                        placeholder="how much to credit?"
                        filled
                        />
                </v-flex>
            </div>
        </v-card-text>

        <v-card-actions>
        <v-spacer />

        <v-btn
            color="red"
            text
            @click="() => $emit('close')"
        >
            {{ $t('no') }}
        </v-btn>

        <v-btn
            color="green"
            text
            @click="handleCallback"
            :disabled="updating"
            :loading="updating"
        >
            {{ $t('yes') }}
        </v-btn>
        </v-card-actions>
    </v-card>
</template>


<script>

export default {
    data() {
        return {
            updating: false,
            amountToCredit: null
        }
    },
    props: {
        payment: {
            type: Object,
            default: () => null
        }
    },
    methods: {
        handleCallback() {
            if(typeof this.payment === 'undefined' || this.payment === null || typeof this.payment.uuid === 'undefined' || this.payment.uuid === null) {
                return
            }
            this.updating = true
            const _amount = Number(this.amountToCredit)
            this.$store
              .dispatch('creditPayment', {uuid: this.payment.uuid, amount: _amount})
               .then((res) => {
                    if(res) {
                        this.$store.commit('updateActionSuccess', {
                            message: 'Success',
                            subMessage: 'Payment cretited ' + this.amountToCredit
                        })
                    } else {
                        this.$store.commit('updateActionError', {
                            message: 'Error',
                            subMessage: 'Payment not cretited'
                        })
                    }
                })
                .catch(() => {
                    this.$store.commit('updateActionError', {
                        message: 'Error',
                        subMessage: 'Payment not cretited'
                    })
                })
                .finally(() => {
                    this.updating = false
                    this.$emit('close')
                })
        }
    }
}

</script>